@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  /* .btn-primary {
      @apply py-1.5 px-3 text-center bg-blue-700 rounded-md text-white;
  } */
}
@layer base {
  [type="text"],
  [type="email"],
  [type="url"],
  [type="password"],
  [type="number"],
  [type="date"],
  [type="datetime-local"],
  [type="month"],
  [type="search"],
  [type="tel"],
  [type="time"],
  [type="week"],
  [multiple],
  textarea,
  select {
    @apply w-full border-[#3b3b3b] rounded-lg shadow-sm focus:border-[#3490dc] focus:ring-[#3490dc] text-black placeholder-gray-400 bg-white;
  }
  [type="checkbox"],
  [type="radio"] {
    @apply border-[#4a4a4a] rounded text-[#3490dc] focus:ring-[#3490dc];
  }
  [type="file"] {
    @apply border-[#4a4a4a] rounded-lg shadow-sm focus:border-[#3490dc] focus:ring-[#3490dc];
  }
  body {
    @apply font-primary bg-white text-black;
  }
  .dropdown {
    @apply w-full lg:max-w-[296px] cursor-pointer text-black;
  }
  .dropdown-btn {
    @apply flex h-[64px] items-center px-[18px] border rounded-lg bg-white;
  }
  .dropdown-icon-primary {
    @apply text-2xl mr-[18px] text-black bg-white;
  }
  .dropdown-icon-secondary {
    @apply text-2xl text-black bg-white ml-auto;
  }
  .dropdown-menu {
    @apply px-6 py-8 text-[15px] pl-10 space-y-6 shadow-md bg-white text-black absolute w-full z-[1000] list-none rounded-b-lg max-h-[11rem] overflow-y-auto border-[#4a4a4a];
  }
  .aleskya {
    @apply font-secondary text-black bg-white;
  }
}

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

/* scroll bar */
::-webkit-scrollbar {
  width: 5px;
  height: 4px;
}
::-webkit-scrollbar-track {
  background-color: #333333;
  border-radius: 8px;
}
::-webkit-scrollbar-thumb {
  background: #999999;
  border-radius: 8px;
}
::-webkit-scrollbar-thumb:hover {
  background: #999999;
}
/* scroll bar */

/* slider style */
.mySwiper .swiper-button-next:after {
  font-size: 1rem;
  background-color: black;
  padding: 8px 9px 8px 12px;
  border-radius: 50%;
  color: white;
}
.mySwiper .swiper-button-next:hover:after {
  transform: scale(1.2);
}
.mySwiper .swiper-button-prev:after {
  font-size: 1rem;
  background-color: black;
  padding: 8px 12px 8px 9px;
  border-radius: 50%;
  color: white;
}
.mySwiper .swiper-button-prev:hover:after {
  transform: scale(1.2);
}
/* slider style */

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.fade-out {
  animation: fadeOut 0.3s ease-out;
}

.fade-in {
  animation: fadeIn 0.3s ease-in;
}

.slide-in-left {
  animation: slideInFromLeft 0.5s ease-out;
}

.slide-in-right {
  animation: slideInFromRight 0.5s ease-out;
}

/* EasyPaisa Icon */
.cls-1 {
  fill: #26b15d;
}

.cls-1, .cls-2, .cls-3, .cls-4 {
  stroke-width: 0px;
}

.cls-3 {
  fill: #fff;
}

.cls-4 {
  fill: #00c252;
}